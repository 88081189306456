import React from 'react';
import styled, { css } from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import VoiceBanner from '../components/VoiceBanner';
import Costumes from '../components/Costumes';

type BasePagePropsType = {
  className?: string;
};

const BasePage: React.FC<BasePagePropsType> = ({ className }) => {
  return (
    <Layout>
      <div className={className}>
        <SEO />
        <div className="spacer30"></div>
        <div className="row">
          <div className="col-sm-12" style={{ height: 'auto', width: '100%' }}>
            <Carousel
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              showIndicators={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={5000}
              transitionTime={2500}
            >
              <div>
                <StaticImage src={'../images/studio1.jpg'} alt={`image`} />
              </div>
              <div>
                <StaticImage src={'../images/studio2.jpg'} alt={`image`} />
              </div>
              <div>
                <StaticImage src={'../images/studio3.jpg'} alt={`image`} />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="spacer30"></div>
        <div className="row">
          <div className="col-12">
            <div className="title">
              <h2>
                <span>撮影プラン</span>
              </h2>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="home-plan-wrap">
              <Link to="/wedding/">
                <StaticImage src="../images/wedding2.jpg" alt="image" />
                <p className="home-plan-title">フォト婚洋和装プラン</p>
                <div className="text-center mg-lg d-grid gap-2">
                  <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                    さらにくわしく
                  </button>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="home-plan-wrap">
              <Link to="/wedding/">
                <StaticImage src="../images/wedding1.jpg" alt="image" />
                <p className="home-plan-title">フォト婚洋装プラン</p>
                <div className="text-center mg-lg d-grid gap-2">
                  <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                    さらにくわしく
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="home-plan-wrap">
              <Link to="/seijinshiki/">
                <StaticImage src="../images/seijinshiki.jpg" alt="image" />
                <p className="home-plan-title">成人式プラン</p>
                <div className="text-center mg-lg d-grid gap-2">
                  <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                    さらにくわしく
                  </button>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-sm-6"></div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="home-plan-wrap">
              <Link to="/shichigosan/">
                <StaticImage src="../images/753.jpg" alt="image" />
                <p className="home-plan-title">七五三</p>
                <div className="text-center mg-lg d-grid gap-2">
                  <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                    さらにくわしく
                  </button>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="home-plan-wrap">
              <Link to="/omiyamairi/">
                <StaticImage src="../images/omiyamairi.jpg" alt="image" />
                <p className="home-plan-title">お宮参り 百日祝い</p>
                <div className="text-center mg-lg d-grid gap-2">
                  <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                    さらにくわしく
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="home-plan-wrap">
              <Link to="/entree/">
                <StaticImage src="../images/entree.jpg" alt="image" />
                <p className="home-plan-title">ご入園ご入学</p>
                <div className="text-center mg-lg d-grid gap-2">
                  <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                    さらにくわしく
                  </button>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="home-plan-wrap">
              <Link to="/birthday/">
                <StaticImage src="../images/birthday.jpg" alt="image" />
                <p className="home-plan-title">バースデーお誕生日</p>
                <div className="text-center mg-lg d-grid gap-2">
                  <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                    さらにくわしく
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <Costumes />
        <div className="row">
          <div className="col-sm-12">
            <VoiceBanner category={'home'} />
          </div>
        </div>
        <div className="spacer20"></div>
        <div className="row">
          <div className="col-sm-12">
            <div className="home-plan-wrap">
              <a href="https://lin.ee/dco60Ga">
                <StaticImage src="../images/LINE.jpg" alt="image" />
              </a>
              <p className="home-plan-title">LINEを通じてご質問にお答えします！</p>
              <div className="text-center mg-lg d-grid gap-2">
                <a
                  href="https://lin.ee/dco60Ga"
                  className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg"
                >
                  お問い合わせ・ご予約はこちら
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="home-plan-wrap">
              <StaticImage src="../images/photook.png" alt="image" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const Page = styled(BasePage)`
  ${() => {
    return css`
      & {
        .spacer10 {
          margin-bottom: 10px;
        }

        .spacer20 {
          margin-bottom: 20px;
        }

        .spacer30 {
          margin-bottom: 30px;
        }

        .spacer50 {
          margin-bottom: 50px;
        }

        .spacer100 {
          margin-bottom: 100px;
        }
        .home-plan-wrap {
          margin: 0 0 10px;
          padding: 10px;
          border: solid 1px #eee;
          border-radius: 10px;
        }
        .home-plan-title {
          padding: 10px 0;
          margin: 10px 0;
          font-size: 1.4rem;
          font-weight: 200;
          line-height: 2rem;
          text-align: center;
        }
        p.home-bg-list {
          font-size: 1.1rem;
          font-weight: 200;
          line-height: 1.2rem;
        }
        p.home-bg-list span {
          margin: 0 5px 5px 0;
        }

        .btn-rd {
          border-radius: 40px !important;
        }

        .btn-tickle-me-pink {
          background: #ff7fa9 !important;
          color: #ffffff !important;
        }
        .main-title {
          color: red;
          font-weight: 700;
          font-size: 2rem;
          margin: 30px auto;
          text-align: center;
          word-wrap: break-word;
          line-height: 1.5;
        }

        .home-plan-wrap > a {
          color: #000000;
          text-decoration: none;
          font-weight: 600;
        }

        .title {
          h2 {
            position: relative;
            margin: 30px;
            text-align: center;
          }

          h2:before,
          h2:after {
            position: absolute;
            z-index: 0;
            bottom: -10px;
            display: block;
            content: '';
            border: 1em solid rgb(190, 36, 88);
          }

          h2:before {
            left: -30px;
            border-left-width: 15px;
            border-left-color: transparent;
          }

          h2:after {
            right: -30px;
            border-right-width: 15px;
            border-right-color: transparent;
          }

          h2 span {
            position: relative;
            z-index: 1;
            display: block;
            padding: 1rem 2rem;
            color: #fff;
            background: rgb(245, 90, 141);
          }

          h2 span:before,
          h2 span:after {
            position: absolute;
            bottom: -10px;
            display: block;
            width: 10px;
            height: 10px;
            content: '';
            border-style: solid;
            border-color: #b70505 transparent transparent transparent;
          }

          h2 span:before {
            left: 0;
            border-width: 10px 0 0 10px;
          }

          h2 span:after {
            right: 0;
            border-width: 10px 10px 0 0;
          }
        }

        @media screen and (max-width: 480px) {
          .main-title {
            font-size: 1rem;
            margin: 20px auto;
          }
        }
      }
    `;
  }}
`;
export default Page;
