import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

type BaseVoiceBannerPropsType = {
  className?: string;
  category: string;
};

const BaseVoiceBanner: React.FC<BaseVoiceBannerPropsType> = ({ className, category }) => (
  <div className={className}>
    <h1>お客様の声</h1>
    <div className="voice-wrapper">
      <Link to={`/voice?category=${category}`}>
        <h2>成人式の前撮りで利用しました。 感染対策も十分にさ...</h2>
        <span className="category">成人式 撮影のお客様</span>
      </Link>
    </div>
    <div className="voice-wrapper">
      <Link to={`/voice?category=${category}`}>
        <h2>今回ハーフバースデーで初めて撮影していただきました...</h2>
        <span className="category">バースデー 撮影のお客様</span>
      </Link>
    </div>
    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
      <Link to={`/voice?category=${category}`}>さらに表示</Link>
    </div>
  </div>
);

const VoiceBanner = styled(BaseVoiceBanner)`
  ${() => {
    return css`
      & {
        border-radius: 10px;
        border: solid 1px #eeeeee;
        padding: 20px auto;
        h1 {
          font-size: 26px;
          font-weight: 600;
          text-align: left;
          margin: 20px 0;
          color: #5a5a5a;
          text-align: center;
        }
        .voice-wrapper {
          display: flex;
          flex-direction: column;
          line-height: 1;
          margin: 0 20px 20px 20px;
          a {
            text-decoration: none;
            text-align: center;
            color: #5a5a5a;
            margin-bottom: 10px;
          }
          h2 {
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            color: #fda359;
            margin: 0;
            margin-bottom: 5px;
          }
          span.category {
            font-size: 14px;
            font-weight: 600;
            text-align: right;
            width: 100%;
            display: inline-block;
            margin-bottom: 5px;
          }
        }
      }
    `;
  }}
`;

export default React.memo(VoiceBanner);
